import { Controller } from '@hotwired/stimulus'
import toastr from 'toastr'
export default class extends Controller {
  static targets = ['comparisonArea', 'compareButton', 'checkbox', 'comparedCount', 'resetButton']
  connect() {
    this.updateComparisonCount();
    this.compareButtonTarget.addEventListener('click', this.handleCompareClick);
    this.resetButtonTarget.addEventListener('click', this.handleResetClick);
    this.fetchComparisonSchools();
    toastr.clear();

    // Listen to Turbo Drive events
    document.addEventListener('turbo:load', () => {
      toastr.clear()
      this.fetchComparisonSchools();
    });
  }

  fetchComparisonSchools() {
    fetch('/schools/fetch_comparison_schools')
      .then(response => response.json())
      .then(data => {
        this.updateComparisonArea(data);
        this.initializeCheckboxState(data);
      })
      .catch(error => console.error('Error fetching comparison schools:', error));
  }
  initializeCheckboxState(data) {
    const comparisonSchoolIds = data.map(school => school.id.toString());
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = comparisonSchoolIds.includes(checkbox.dataset.comparisonSchoolId);
    });
  }
  addToComparison(event) {
    event.preventDefault();
    const schoolId = event.currentTarget.dataset.comparisonSchoolId;

    fetch(`/schools/add_to_comparison?school_no=${schoolId}`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        Accept: 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        toastr.info("Added to Compare List");
        this.updateComparisonCount();
        this.fetchComparisonSchools();
      } else {
        toastr.error('Error adding to comparison list.');
      }
    }).catch(error => console.error('Error adding to comparison list:', error));
  }


  toggleComparison(event) {
    event.preventDefault();
    const checkbox = event.currentTarget;
    const schoolId = checkbox.dataset.comparisonSchoolId;
    const isChecked = checkbox.checked;
    const action = isChecked ? 'add_to_comparison' : 'remove_from_comparison';

    fetch(`/schools/${action}?school_no=${schoolId}`, {
      method: action === 'add_to_comparison' ? 'POST' : 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        Accept: 'text/html'
      }
    }).then(response => {
      if (response.ok) {
        //this.updateComparisonArea();
        this.updateComparisonCount();
        this.updateCheckboxState(schoolId, isChecked); // Ensure checkbox state is updated
        this.fetchComparisonSchools();
        toastr.info(isChecked ? "Added to Compare List" : "Removed from Compare List");
      } else if (response.status === 422) {
        // Handle the case where a fourth school is being added
        response.json().then(data => {
          toastr.error("You already have 3 schools in your compare list. Please delete at least one before adding a new school!");
          checkbox.checked = false; // Revert the checkbox state
        })
      } else {
        alert('Error updating comparison list.');
        checkbox.checked = !isChecked; // Revert the checkbox state
      }
    })
  }



  updateCheckboxState(schoolNo, isChecked) {
    const checkbox = this.element.querySelector(`[data-comparison-school-id="${schoolNo}"]`);
    if (checkbox) {
      checkbox.checked = isChecked;
      checkbox.dataset.state = isChecked ? 'added' : 'removed';
    }
  }

  updateComparisonArea(schoolsData) {
    // Get all the placeholder elements
    const placeholders = this.element.querySelectorAll('.school-placeholder');

    // Hide all placeholders and images initially
    placeholders.forEach(placeholder => {
      placeholder.querySelector('.school-compare-image').style.display = 'none';
      placeholder.querySelector('.default-placeholder').style.display = 'block';
    });

    // Loop through the schools data and update the placeholders
    schoolsData.forEach((school, index) => {
      if (index < placeholders.length) {
        const placeholder = placeholders[index];
        const imageElement = placeholder.querySelector('.school-compare-image');
        const defaultPlaceholderElement = placeholder.querySelector('.default-placeholder');

        imageElement.src = school.imageUrl; // Set the image URL
        imageElement.style.display = 'block'; // Show the image
        defaultPlaceholderElement.style.display = 'none'; // Hide the default placeholder
      }
    });
  }


  updateComparisonCount() {
    fetch('/schools/comparison_count')
      .then(response => response.json())
      .then(data => {
        const comparisonCount = data.count;
        console.log(comparisonCount)
        this.comparedCountTarget.innerText = comparisonCount
        this.updateCompareButtonState(comparisonCount)
      })
      .catch(error => console.error('Error fetching comparison count:', error));
  }

  updateCompareButtonState(count) {
    const isEnoughSchools = count >= 2 && count <= 3;
    this.compareButtonTarget.disabled = !isEnoughSchools;
    this.compareButtonTarget.textContent = 'Compare Now'
    this.compareButtonTarget.classList.toggle('disabled-compare', !isEnoughSchools);
  }


  handleCompareClick = () => {
    if (!this.compareButtonTarget.disabled) {
      window.location.href = '/compare'; // Replace '/compare' with your actual compare path
    }
  }
  handleResetClick = () => {
    fetch('/reset_comparison', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        Accept: 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        toastr.info('Comparison list has been reset.');
        this.clearCheckboxes();
        this.updateComparisonCount();
        this.fetchComparisonSchools();
      } else {
        toastr.error('There was an issue resetting the comparison list.');
      }
    }).catch(error => console.error('Error resetting comparison list:', error));
  }

  clearCheckboxes() {
    // Iterate over all checkboxTargets and uncheck them
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
      // Also reset the 'data-state' attribute and any styling applied to the styled checkbox
      checkbox.dataset.state = 'removed';
      const styledCheckbox = checkbox.nextElementSibling; // Assuming the styled span immediately follows the checkbox
      if (styledCheckbox) {
        styledCheckbox.classList.remove('active');
      }
    });
  }


}
