// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import 'bootstrap'
import "@rails/actioncable"
import "chartkick/chart.js"

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import toastr from 'toastr'
window.Chartkick = Chartkick;
