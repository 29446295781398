// app/javascript/controllers/favorites_controller.js
import { Controller } from '@hotwired/stimulus'
import toastr from 'toastr'
export default class extends Controller {
  static targets = ['heartIcon', 'toggleFavoritesText', 'badge', 'favoritesButton']
  connect() {
    this.favorites = JSON.parse(localStorage.getItem('favorites')) || []
    this.updateHeartIcons()
    this.updateFavoritesButton()

  }
 toggleFavorite(event) {
    const schoolId = event.currentTarget.dataset.schoolId
    const index = this.favorites.indexOf(schoolId)

    if (index === -1) {
      this.favorites.push(schoolId)
      toastr.success('Added to favorites')
    } else {
      this.favorites.splice(index, 1)
      toastr.warning('Removed from favorites')
    }

    localStorage.setItem('favorites', JSON.stringify(this.favorites))
    this.updateHeartIcon(schoolId)
    this.updateFavoritesButton()
  }

  showFavorites () {
    const favorites = JSON.parse(localStorage.getItem('favorites')) || []
    if (window.location.pathname.includes('/favorites')) {
      // Redirect to the main page (show all schools) if currently on favorites page
      window.location.href = '/'
    } else {
      // Redirect to the favorites page with favorite IDs if not currently on favorites page
      window.location.href = `/favorites?favorite_ids=${encodeURIComponent(
        JSON.stringify(favorites)
      )}`
    }
  }

  updateFavoritesButton() {
    const isFavoritesPage = window.location.pathname.includes('/favorites');

    // If on the favorites page, show "View All Schools", otherwise show the count of favorites
    if (isFavoritesPage) {
      this.favoritesButtonTarget.textContent = 'View All';
    } else {
      const favoritesCount = this.favorites.length;
      this.favoritesButtonTarget.textContent = `View favorites (${favoritesCount})`;
    }

    // Update the badge content
    const badgeContent = isFavoritesPage ? this.element.dataset.totalSchoolsCount : this.favorites.length;
    if (this.hasBadgeTarget) {
      this.badgeTarget.textContent = badgeContent;
    }
  }

  updateHeartIcons () {
    this.heartIconTargets.forEach(icon => {
      const schoolId = icon.dataset.schoolId
      this.updateHeartIcon(schoolId)
    })
  }

  updateHeartIcon (schoolId) {
    const heartIcon = this.element.querySelector(`#heart-icon-${schoolId}`)
    if (heartIcon) {
      const isFavorite = this.favorites.includes(schoolId)
      heartIcon.classList.toggle('fas', isFavorite)
      heartIcon.classList.toggle('far', !isFavorite)
      heartIcon.style.color = isFavorite ? '#DB7833' : '#ffffff'
      heartIcon.style.backgroundColor = isFavorite ? '#ffffff' : '#d3d2d2'
    }
  }
}
