import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["shortText", "fullText", "button", "overlay"];
  connect() {
    this.initializeTooltips()
    if (this.isMobile()) {
      this.overlayTarget.classList.remove('d-none')
      this.overlayTarget.style.display = 'flex';
    }
    else {
      this.overlayTarget.classList.add('d-none')
      this.overlayTarget.style.display = 'none';
      console.log(this.overlayTarget)
    }
  }


  initializeTooltips () {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
      new bootstrap.Tooltip(tooltipTriggerEl, {
        offset: [5, 15] // Adjust the offset as needed
      })
    })
  }

  isMobile() {
    const touchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return touchScreen && screenWidth <= 768; // 768px is a common breakpoint for mobile devices.
  }

  dismissOverlay() {
    this.overlayTarget.style.display = 'none';
  }

  toggleText(event) {
    const detailId = event.currentTarget.dataset.detailId;
    const shortText = this.shortTextTargets.find(target => target.id === `text-short-${detailId}`);
    const fullText = this.fullTextTargets.find(target => target.id === `text-full-${detailId}`);

    if (shortText && fullText) {
      if (shortText.classList.contains('d-none')) {
        shortText.classList.remove('d-none');
        fullText.classList.add('d-none');
        event.currentTarget.textContent = 'Show More';
      } else {
        shortText.classList.add('d-none');
        fullText.classList.remove('d-none');
        event.currentTarget.textContent = 'Show Less';
      }
    }
  }
}
