import { Controller } from '@hotwired/stimulus';
import toastr from 'toastr';
import { Loader } from '@googlemaps/js-api-loader';

export default class extends Controller {
  static targets = ['mapView', 'schoolList', 'toggleViewBtn', 'filtersInterface', 'addressInput', 'schoolData'];
  infoWindow;
  connect() {
    this.toggleViewBtnTarget.addEventListener('click', () => this.toggleMobileView())
    if (!this.map) {
      this.initMap();
      this.updateSchoolMarkers();
      this.adjustMapHeight()
    } else {
      // Optionally, update or refresh the map or its markers if needed
      this.updateSchoolMarkers();
      this.adjustMapHeight()
    }
    window.addEventListener('resize', () => this.adjustMapHeight())
    this.updateHomeMarker(this.map, mapCenter)
  }

  async initMap() {

    const urlParams = new URLSearchParams(window.location.search);
    const schoolSearch = urlParams.get('school_name');

    const loader = new Loader({
      apiKey: "AIzaSyCI5j0FiUlwfjqEJ2EL-Q_a5wP7Cs_JTLA",
      version: "weekly",
      libraries: ["places"]
    });
    const google = await loader.load()
    let mapCenter = await this.getMapCenter();
    //home Icon for the map
    const homeIcon = {
      url: 'https://www.clker.com/cliparts/g/9/4/c/Y/0/orange-map-pin.svg.thumb.png', // URL to your home icon image
      scaledSize: new google.maps.Size(32, 42) // Adjust size as needed
    };

    //Map POI styling
    const schoolMapStyle = [{
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{
        visibility: 'off'
      }]
    }]

    this.map = new google.maps.Map(this.mapViewTarget, {
      center: mapCenter,
      zoom: 13,
      gestureHandling: 'greedy',
      style: schoolMapStyle,
    });


    this.infoWindow = new google.maps.InfoWindow()
    this.updateSchoolMarkers(this.map)
    this.initGooglePlacesAutocomplete();
    const schools = JSON.parse(this.schoolDataTarget.dataset.schools);
    if (schoolSearch && schools.length < 5 && schools.length > 0) {
      mapCenter = new google.maps.LatLng(schools[0].school_lat, schools[0].school_lon)
    }
    this.updateHomeMarker(this.map, mapCenter)


  }

  adjustMapHeight() {
    const navbarHeight = 70; // Adjust this if your navbar height changes
    const windowHeight = window.innerHeight
    const mapHeight = windowHeight - navbarHeight
    this.mapViewTarget.style.height = `${mapHeight}px`
  }
  getMapCenter() {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams(window.location.search);
      const userAddress = urlParams.get('address');
      let neighborhoodFilter = urlParams.get('neighborhoodFilter');

      if (neighborhoodFilter) {
        neighborhoodFilter += ', Denver, Colorado';
      }

      const addressToGeocode = userAddress || neighborhoodFilter || '39.7392, -104.9903';

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: addressToGeocode }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          resolve(results[0].geometry.location);
        } else {
          reject('Geocoding failed: ' + status);
        }
      });
    });
  }

	initGooglePlacesAutocomplete() {
		const input = document.getElementById('address-input');
		const form = input.closest('form'); // Get the nearest form ancestor
		const autocomplete = new google.maps.places.Autocomplete(input, {
			types: ['geocode'], // Restrict to geographic locations
			bounds: new google.maps.LatLngBounds(
				new google.maps.LatLng(39.614, -105.227), // Southwestern boundary
				new google.maps.LatLng(40.06, -104.611) // Northeastern boundary
			)
		});

		autocomplete.addListener('place_changed', function() {
			const place = autocomplete.getPlace();
			if (!place.geometry) {
				console.warn("No details available for input: '" + place.name + "'");
				return;
			}
			// Use place details as needed
			form.submit(); // Submit the form when a place is selected
		});
	}

  updateHomeMarker(map, location) {

    const homeIcon = {
      url: 'https://www.clker.com/cliparts/g/9/4/c/Y/0/orange-map-pin.svg.thumb.png', // URL to your home icon image
      scaledSize: new google.maps.Size(32, 42) // Adjust size as needed
    };

    new google.maps.Marker({
      position: location,
      map: map,
      icon: homeIcon
    });
  }



  async updateSchoolMarkers(map) {

    let schools = await this.loadSchoolData();


    if (this.isFavoritesRoute()) {
			toastr.info("You are now on the Favorites page")
			const favorites = JSON.parse(localStorage.getItem('favorites')) || []
			schools = schools.filter(school =>
				favorites.includes(String(school.school_no))
			)
		}
    console.log("School latitude:", schools[0].school_lat, "Longitude:", schools[0].school_lon);
    schools.forEach(school => {
      const schoolLatLng = new google.maps.LatLng(
        school.school_lat,
        school.school_lon
      )
      const icon = {
        url: 'https://i.imgur.com/QzBNRIq.png', // URL to the icon image
        scaledSize: new google.maps.Size(36, 40), // Scaling the icon size
        origin: new google.maps.Point(0, 0), // Origin point of the icon
        anchor: new google.maps.Point(18, 40) // Anchor point of the icon (adjusted for the icon size)
      }
      const marker = new google.maps.Marker({
        position: schoolLatLng,
        map: map,
        title: school.school_name,
        icon: icon
      })
      marker.addListener('click', () => {
        const content = `
          <h6>${school.school_name}</h6>
          <p>${school.address_street}, ${school.address_city}, ${
                    school.address_state
                  } ${school.address_zip}</p>
          <p>Phone: ${school.phone || 'N/A'}</p>
          <p>Principal: ${school.principal_name || 'N/A'}</p>
          <p>Grade Span: ${
            school.grade_span || 'N/A'
          }</p> <!-- This will use Rails helper -->
          <p>Enrollment: ${school.enrollment || 'N/A'}</p>
          <p>Neighborhood: ${school.address_neighborhood || 'N/A'}</p>
          <a href="/schools/${
            school.school_no
          }" class="btn btn-primary">View Details</a>
`

        this.infoWindow.setContent(content)
        this.infoWindow.open(map, marker)
      })
    })

  }

  async loadSchoolData() {
		// Get the filter values from the checkboxes / select boxes
		const urlParams = new URLSearchParams(window.location.search)
		const gradeFilter = urlParams.get('gradeFilter')
		const neighborhoodFilter = urlParams.get('neighborhoodFilter')
    const schoolSearch = urlParams.get('school_name')
		// Set the select element's value to the retrieved value (if it exists)
		if (gradeFilter) {
			document.getElementById('gradeFilter').value = gradeFilter
		}

		let url = `/schools/data?`

		if (gradeFilter) {
			url += `gradeFilter=${gradeFilter}`
		}

		if (neighborhoodFilter) {
			url += `&neighborhoodFilter=${neighborhoodFilter}` // Add neighborhood filter to the URL
		}

    if (schoolSearch) {
      url += `&school_name=${schoolSearch}`
    }

		return fetch(url)
			.then(response => response.json())
			.then(data => {
				this.schoolDataTarget.dataset.schools = JSON.stringify(data)
        return JSON.parse(this.schoolDataTarget.dataset.schools)
			})
			.catch(error => {
				console.error('Error loading school data:', error)
			})
	}

	isFavoritesRoute() {
		return window.location.pathname.includes('/favorites')
	}

  filtersdisplay() {
		const filtersInterface = this.filtersInterfaceTarget
		console.log(filtersInterface.style.width)
		if (
			!filtersInterface.style.width ||
			filtersInterface.style.width === '0%'
		) {
			filtersInterface.style.display = 'block'
			if (window.innerWidth <= 768) {
				filtersInterface.style.width = '100%'
			} else {
				filtersInterface.style.width = '50%'
			}
		} else {
			filtersInterface.style.display = 'none'
			filtersInterface.style.width = '0%'
		}
	}

	toggleFilters() {
		const filtersCollapse = document.getElementById('filtersCollapse')
		if (filtersCollapse) {
			if (filtersCollapse.classList.contains('show')) {
				filtersCollapse.classList.remove('show')
			} else {
				filtersCollapse.classList.add('show')
			}
		}
	}

  toggleMobileView() {
    if (this.mapViewTarget.style.display === 'none') {
      // Show the map and hide the list view
      this.mapViewTarget.style.display = 'block';
      this.schoolListTarget.style.display = 'none';
      this.toggleViewBtnTarget.innerHTML = '<i class="fa-solid fa-magnifying-glass"></i>';
    } else {
      // Show the list view and hide the map
      this.mapViewTarget.style.display = 'none';
      this.schoolListTarget.style.display = 'block';
      this.toggleViewBtnTarget.innerHTML = '<i class="fa-solid fa-map-location-dot"></i>';
    }
  }
}
