// app/javascript/controllers/schoolpage_controller.js
import { Controller } from '@hotwired/stimulus'
import toastr from 'toastr'

export default class extends Controller {
  static targets = ["heart"]

  connect() {
    this.updateHeartIcon();
  }

  toggleFavorite(event) {
    event.preventDefault();
    const schoolId = this.data.get('school-id');
    let favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    const isFavorite = favorites.includes(schoolId);

    if (isFavorite) {
      favorites = favorites.filter(fav => fav !== schoolId);
      toastr.warning('Removed from favorites');
    } else {
      favorites.push(schoolId);
      toastr.success('Added to favorites');
    }

    localStorage.setItem('favorites', JSON.stringify(favorites));
    this.updateHeartIcon();
  }

  updateHeartIcon() {
    const schoolId = this.data.get('school-id');
    const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    const isFavorite = favorites.includes(schoolId);

    this.heartTargets.forEach((icon) => {
      if (isFavorite) {
        icon.classList.remove('far');
        icon.classList.add('fas');
        icon.style.color = '##F4F4F4'; // Or any color when favorited
      } else {
        icon.classList.remove('fas');
        icon.classList.add('far');
        icon.style.color = 'inherit'; // Or any color when not favorited
      }
    });
  }
}
