import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {

      if (!localStorage.getItem("modalShown")) {
        this.showModal();
        localStorage.setItem("modalShown", "true");
      }
    }



  showModal() {
    const modalElement = document.getElementById('welcomeModal');
    const modal = new Modal(modalElement, {});
    modal.show();

    setTimeout(() => {
      modal.hide();
    }, 10000); // Auto-close after 10 seconds
  }

}
