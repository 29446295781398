import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {

  connect() {
    this.channel = createConsumer().subscriptions.create(
      { channel: "AdminChannel", id: 1 }, // Channel identifier and options separated
      { received: data => console.log(data) }
    )
    //console.log(`Subscribed to the AdminChannel with the id 1.`)
  }
}
