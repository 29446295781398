import {
	Controller
} from '@hotwired/stimulus'
import toastr from 'toastr'

export default class extends Controller {
  static targets = ['mapView', 'schoolList', 'toggleViewBtn', 'filtersInterface', 'addressInput', 'schoolData'];
  map;
  infoWindow;


  setHomeMarker() {
    const homeIcon = {
      url: 'https://www.clker.com/cliparts/g/9/4/c/Y/0/orange-map-pin.svg.thumb.png',
      scaledSize: new google.maps.Size(32, 42),
    };

    new google.maps.Marker({
      position: this.map.getCenter(),
      map: this.map,
      icon: homeIcon,
    });
  }

  setSchoolMarkers(schools) {
    const displacement = 0.0001;
    const seenCoordinates = new Set();

    schools.forEach(school => {
      let latLngKey = `${school.school_lat},${school.school_lon}`;
      while (seenCoordinates.has(latLngKey)) {
        school.school_lat += displacement;
        school.school_lon += displacement;
        latLngKey = `${school.school_lat},${school.school_lon}`;
      }
      seenCoordinates.add(latLngKey);

      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(school.school_lat, school.school_lon),
        map: this.map,
        title: school.school_name,
        icon: {
          url: 'https://i.imgur.com/QzBNRIq.png',
          scaledSize: new google.maps.Size(36, 40),
        },
      });

      marker.addListener('click', () => {
        const content = `
          <h6>${school.school_name}</h6>
          <p>${school.address_street}, ${school.address_city}, ${school.address_state} ${school.address_zip}</p>
          <p>Phone: ${school.phone || 'N/A'}</p>
          <p>Principal: ${school.principal_name || 'N/A'}</p>
          <p>Grade Span: ${school.grade_span || 'N/A'}</p>
          <p>Enrollment: ${school.enrollment || 'N/A'}</p>
          <p>Neighborhood: ${school.address_neighborhood || 'N/A'}</p>
          <a href="/schools/${school.school_no}" class="btn btn-primary">View Details</a>
        `;

        this.infoWindow.setContent(content);
        this.infoWindow.open(this.map, marker);
      });
    });
  }

	async connect() {
		let schools = await this.loadSchoolData()
		this.initGooglePlacesAutocomplete()
		this.initSidebarToggleEvents()

    if (this.isFavoritesRoute()) {
      toastr.info('You are now on the Favorites page');
      const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
      schools = schools.filter(school => favorites.includes(String(school.school_no)));
    }

		if (this.isFavoritesRoute()) {
			toastr.info("You are now on the Favorites page")
			const favorites = JSON.parse(localStorage.getItem('favorites')) || []
			schools = schools.filter(school =>
				favorites.includes(String(school.school_no))
			)
		}
		const mapView = this.mapViewTarget
		const schoolList = this.schoolListTarget
		const toggleViewBtn = this.toggleViewBtnTarget
		let map
		let centerLatLng
		let infoWindow
		// Function to initialize the map
		const initMap = () => {
			const schoolMapStyle = [{
				featureType: 'poi',
				elementType: 'labels',
				stylers: [{
					visibility: 'off'
				}]
			}]

			infoWindow = new google.maps.InfoWindow()

      const urlParams = new URLSearchParams(window.location.search);
      const schoolSearch = urlParams.get('school_name');
      let mapCenter = centerLatLng; // Default to previously determined center

      if (schoolSearch && schools.length < 5 && schools.length > 0) {
        mapCenter = new google.maps.LatLng(schools[0].school_lat, schools[0].school_lon);
      }


			map = new google.maps.Map(mapView, {
				center: mapCenter,
				zoom: 13,
				streetViewControl: false,
				styles: schoolMapStyle,
        gestureHandling: 'greedy',
			})

			const homeIcon = {
				url: 'https://www.clker.com/cliparts/g/9/4/c/Y/0/orange-map-pin.svg.thumb.png', // URL to your home icon image
				scaledSize: new google.maps.Size(32, 42) // Adjust size as needed
			};

			new google.maps.Marker({
				position: mapCenter,
				map: map,
				icon: homeIcon
			});

			// Add markers for each school
			schools.forEach(school => {
				const schoolLatLng = new google.maps.LatLng(
					school.school_lat,
					school.school_lon
				)
				const icon = {
					url: 'https://i.imgur.com/QzBNRIq.png', // URL to the icon image
					scaledSize: new google.maps.Size(36, 40), // Scaling the icon size
					origin: new google.maps.Point(0, 0), // Origin point of the icon
					anchor: new google.maps.Point(18, 40) // Anchor point of the icon (adjusted for the icon size)
				}
				const marker = new google.maps.Marker({
					position: schoolLatLng,
					map: map,
					title: school.school_name,
					icon: icon
				})
				marker.addListener('click', () => {
					const content = `
            <h6>${school.school_name}</h6>
            <p>${school.address_street}, ${school.address_city}, ${
                      school.address_state
                    } ${school.address_zip}</p>
            <p>Phone: ${school.phone || 'N/A'}</p>
            <p>Principal: ${school.principal_name || 'N/A'}</p>
            <p>Grade Span: ${
              school.grade_span || 'N/A'
            }</p> <!-- This will use Rails helper -->
            <p>Enrollment: ${school.enrollment || 'N/A'}</p>
            <p>Neighborhood: ${school.address_neighborhood || 'N/A'}</p>
            <a href="/schools/${
              school.school_no
            }" class="btn btn-primary">View Details</a>
`

					infoWindow.setContent(content)
					infoWindow.open(map, marker)
				})
			})

		}

		// Load Google Maps API and initialize the map
    const initGoogleMaps = () => {
      if (typeof google === 'undefined') {
        const script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCI5j0FiUlwfjqEJ2EL-Q_a5wP7Cs_JTLA&libraries=places&callback=initGoogleMaps';
        document.head.appendChild(script);
      } else {
        // Retrieve the address and neighborhood from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const userAddress = urlParams.get('address');
        let neighborhoodFilter = urlParams.get('neighborhoodFilter');

        if (neighborhoodFilter) {
          neighborhoodFilter += ', Denver, Colorado';
        }

        const addressToGeocode = userAddress || neighborhoodFilter || '39.7392, -104.9903';


        // Geocode the address or neighborhood to get the latitude and longitude
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: addressToGeocode }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            // Use the first result's geometry location as the center
            centerLatLng = results[0].geometry.location;
          } else {
            // Fallback to central Denver as the default center
            centerLatLng = new google.maps.LatLng(39.7392, -104.9903);
          }
          initMap();
        });
      }
    };

		initGoogleMaps()
    window.addEventListener('resize', () => this.adjustMapHeight());

		// Function to toggle the view
		const toggleView = () => {
			if (mapView.style.display === 'none') {
				// Show the map and hide the list view
				mapView.style.display = 'block'
				schoolList.style.display = 'none'
				toggleViewBtn.innerHTML = '<i class="fa-solid fa-magnifying-glass"></i>'
			} else {
				// Show the list view and hide the map
				mapView.style.display = 'none'
				schoolList.style.display = 'block'
				toggleViewBtn.innerHTML = '<i class="fa-solid fa-map-location-dot"></i>'
			}
		}

		// Toggle view button click event
		toggleViewBtn.addEventListener('click', toggleView)

		// Hide toggle view button on desktop screens
		if (toggleViewBtn.dataset.hideOnDesktop) {
			if (window.innerWidth >= 768) {
				toggleViewBtn.style.display = 'none'
			}
		}
	}

  adjustMapHeight() {
    const navbarHeight = 70; // Adjust this if your navbar height changes
    const windowHeight = window.innerHeight;
    console.log("Adjusted map height to fix available vh")
    const mapHeight = windowHeight - navbarHeight;
    this.mapViewTarget.style.height = `${mapHeight}px`;
  }
  getNeighborhoodFilter() {
    const neighborhoodSelect = document.getElementById('neighborhoodFilter');
    return neighborhoodSelect ? neighborhoodSelect.value : '';
  }
	createHomeMarker(lat, lng) {
		const homeLatLng = new google.maps.LatLng(lat, lng);
		new google.maps.Marker({
			position: homeLatLng,
			map: this.map,
			icon: {
				url: 'https://i.imgur.com/IiSihfe.png', // URL to your home icon image
				scaledSize: new google.maps.Size(36, 40) // Adjust size as needed
			}
		});
	}

	initGooglePlacesAutocomplete() {
		const input = document.getElementById('address-input');
		const form = input.closest('form'); // Get the nearest form ancestor
		const autocomplete = new google.maps.places.Autocomplete(input, {
			types: ['geocode'], // Restrict to geographic locations
			bounds: new google.maps.LatLngBounds(
				new google.maps.LatLng(39.614, -105.227), // Southwestern boundary
				new google.maps.LatLng(40.06, -104.611) // Northeastern boundary
			)
		});

		autocomplete.addListener('place_changed', function() {
			const place = autocomplete.getPlace();
			if (!place.geometry) {
				console.warn("No details available for input: '" + place.name + "'");
				return;
			}
			// Use place details as needed
			form.submit(); // Submit the form when a place is selected
		});
	}

	updateFavoritesCount() {
		const favorites = JSON.parse(localStorage.getItem('favorites')) || []
	}

	initSidebarToggleEvents() {
		const compareButton = document.getElementById('compare-menu-button')
		console.log(compareButton)
		const sidebar = document.getElementById('comparison-sidebar')
		const closeSidebarButton = document.querySelector('.close-sidebar')

		if (compareButton && sidebar) {
			compareButton.addEventListener('click', function() {
				sidebar.style.left = '0'
			})
		}

		if (closeSidebarButton && sidebar) {
			closeSidebarButton.addEventListener('click', function() {
				sidebar.style.left = '-100%'
			})
		}
	}
	isFavoritesRoute() {
		return window.location.pathname.includes('/favorites')
	}
	filtersdisplay() {
		const filtersInterface = this.filtersInterfaceTarget
		console.log(filtersInterface.style.width)
		if (
			!filtersInterface.style.width ||
			filtersInterface.style.width === '0%'
		) {
			filtersInterface.style.display = 'block'
			if (window.innerWidth <= 768) {
				filtersInterface.style.width = '100%'
			} else {
				filtersInterface.style.width = '50%'
			}
		} else {
			filtersInterface.style.display = 'none'
			filtersInterface.style.width = '0%'
		}
	}

	toggleFilters() {
		const filtersCollapse = document.getElementById('filtersCollapse')
		if (filtersCollapse) {
			if (filtersCollapse.classList.contains('show')) {
				filtersCollapse.classList.remove('show')
			} else {
				filtersCollapse.classList.add('show')
			}
		}
	}

	async loadSchoolData() {
		// Get the filter values from the checkboxes / select boxes
		const urlParams = new URLSearchParams(window.location.search)
		const gradeFilter = urlParams.get('gradeFilter')
		const neighborhoodFilter = urlParams.get('neighborhoodFilter')
    const schoolSearch = urlParams.get('school_name')

		// Set the select element's value to the retrieved value (if it exists)
		if (gradeFilter) {
			document.getElementById('gradeFilter').value = gradeFilter
		}

		let url = `/schools/data?`

		if (gradeFilter) {
			url += `gradeFilter=${gradeFilter}`
		}

		if (neighborhoodFilter) {
			url += `&neighborhoodFilter=${neighborhoodFilter}` // Add neighborhood filter to the URL
		}

    if (schoolSearch) {
      url += `&school_name=${schoolSearch}`
    }

		return fetch(url)
			.then(response => response.json())
			.then(data => {
				this.schoolDataTarget.dataset.schools = JSON.stringify(data)
				return JSON.parse(this.schoolDataTarget.dataset.schools)
			})
			.catch(error => {
				console.error('Error loading school data:', error)
			})
	}


}
