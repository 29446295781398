import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['latitude', 'longitude']
  connect () {
    this.initializeTooltips()
    document.addEventListener('turbo:load', this.initSchoolMap.bind(this))
    if (typeof google !== 'undefined') {
      this.initSchoolMap()
    }
  }

  initializeTooltips () {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
      new bootstrap.Tooltip(tooltipTriggerEl, {
        offset: [5, 15] // Adjust the offset as needed
      })
    })
  }

  initSchoolMap () {
    var schoolLatLng = {
      lat: parseFloat(this.latitudeTarget.innerText),
      lng: parseFloat(this.longitudeTarget.innerText)
    }
    var map = new google.maps.Map(document.getElementById('schoolmap'), {
      zoom: 15,
      center: schoolLatLng
    })

    var marker = new google.maps.Marker({
      position: schoolLatLng,
      map: map
    })
  }
}
